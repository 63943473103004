import {ID, Response} from '../../../../_metronic/helpers'

export type listRecentProject = {
  id?: ID
  name: string
  description: string
  telephoneNumber: string
  state: string
  country: string
  pincode: string
}
export type RecentProjectsQueryResponse = Response<Array<listRecentProject>>

export const initialValues: listRecentProject = {
  name: '',
  description: '',
  telephoneNumber: '',
  state: '',
  country: '',
  pincode: '',
}
