import axios from 'axios' // Importing axios library and AxiosResponse type
import {getToken} from '../../../../auth' // Importing getToken function'

const API_URL = process.env.REACT_APP_API_URLS
const ROLE_URL = `${API_URL}/role-access`

//Get all role depends on crematorium id, employee type 
const getRole = async (data: any) => {
  console.log("data",data)
  const res = await axios.post(`${ROLE_URL}/get-role`,data, getToken())
  return res
}

// Get all submodule
const getModules = async () => {
  const res = await axios.get(`${ROLE_URL}/get-module`, getToken())
  return res
}

// FUNCTION TO CREATE ACCESS FOR A PARTICULAR ROLE
const createRoleAccess = async (data:any) => {
 
  console.log('createroleaccess=>', data)
  const res = await axios.post(`${ROLE_URL}/create-role-access`, {data}, getToken())
  
  return res
}

// FUNCTION TO GET EXISTING PERMISSIONS FOR A ROLE
const getExistingPermissions = async (roleId: any) => {
  const res = await axios.get(`${ROLE_URL}/existing-permissions/${roleId}`, getToken())
  console.log('getroleaccess=>', res)
  return res
}

// FUNCTION TO GET ROLE-WISE SIDEBAR MENU
const getRoleWiseSidebar = async (roleId: any) => {
  const res = await axios.get(`${ROLE_URL}/sidebar-modules/${roleId}`, getToken())
  return res
}

// FUNCTION TO SET PAGE ACCESS FOR ALL BUTTONS
const setRoleAccess = (rolesId: any, modulesId: any) => {
  return axios
    .post(`${ROLE_URL}/role-access-list`, {rolesId, modulesId}, getToken())
    .then((response) => {
      return response
    })
}

// EXPORTING ALL FUNCTIONS TO BE USED IN OTHER COMPONENTS
export {getRole, getModules, createRoleAccess, getExistingPermissions, getRoleWiseSidebar, setRoleAccess}
