/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {CustomerWidget} from './customerWidget'
import {ProjectWidget} from './projectWidget'
import {EmployeeWidget} from './employeeWidget'
import {ProjectTable} from './projectTable'
import {widgetCount} from './core/request'

const DashboardPage: FC = () => {
  const [count, setCount] = useState<any>([])

  useEffect(() => {
    widgetCount().then((res: any) => {
      setCount(res.data.data)
    })
  }, [])
  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          <CustomerWidget
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/graphs/gra007.svg'
            color='#F1416C'
            iconColor='white'
            title={count?.customerCount}
            description='Total Projects'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
        </div>

        <div className='col-xl-4'>
          <ProjectWidget
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/graphs/gra007.svg'
            color='#e38e12'
            iconColor='white'
            title={count?.projectCount}
            description='Total Clients'
            img={toAbsoluteUrl('/media/patterns/vector-2.png')}
          />
        </div>

        <div className='col-xl-4'>
          <EmployeeWidget
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/graphs/gra007.svg'
            color='#df73f1'
            iconColor='white'
            title={count?.employeesCount}
            description='Total Employees'
            img={toAbsoluteUrl('/media/patterns/vector-3.png')}
          />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 gx-xl-12'>
        <div className='col-xl-12'>
          <ProjectTable className='card-xxl-stretch mb-5 mb-xl-8' />
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
