import {AuthModel} from './_models'

const AUTH_LOCAL_STORAGE_KEY = 'skyx_auth_detail'
const getAuth = (): AuthModel | undefined => {
  //debugger;
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  //debugger;
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  //debugger;
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.api_token) {
        //console.log(auth.api_token)
       // console.log('auth.api_token')
        config.headers.Authorization = `Bearer ${auth.api_token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}
function getToken() {
  //debugger
  const lc = localStorage.getItem('skyx_auth_detail') || ''
  let config = {}
  if (lc && lc !== '') {
    const {api_token} = JSON.parse(lc);
   config = {
      headers: {Authorization: `Bearer ${api_token}`},
    }
  }
  return config
}
function getTokenForFormData() {
  const lc = localStorage.getItem('skyx_auth_detail') || ''
  let config = {}
  if (lc && lc !== '') {
    const {api_token} = JSON.parse(lc)

    config = {
      headers: {Authorization: `Bearer ${api_token}`},
    }
  }
  return config
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY, getToken, getTokenForFormData}
