import axios, {AxiosResponse} from 'axios'
import {getToken} from '../../../modules/auth'
import {RecentProjectsQueryResponse} from './model'

const API_URL = process.env.REACT_APP_API_URLS
const WIDGET_URL = `${API_URL}/dashboard/clientWidgetCount`
const PROJECT_LIST_URL = `${API_URL}/dashboard/clientProjectList`

const clientWidgetCount = async (clientId: any): Promise<any> => {
  const result = await axios.get(`${WIDGET_URL}/${clientId}`, getToken())
  return result
}

const clientRecentProject = async (clientId: any): Promise<RecentProjectsQueryResponse> => {
  const result = await axios.get(`${PROJECT_LIST_URL}/${clientId}`, getToken())
  return result
}

export {clientWidgetCount, clientRecentProject}
