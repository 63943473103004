/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {recentProject} from './core/request'
import {listRecentProject, initialValues} from './core/model'
import Avatar from 'react-avatar'
import {Link, useNavigate} from 'react-router-dom'
import {useAuth} from '../../modules/auth'

type Props = {
  className: string
}

const ProjectTable: React.FC<Props> = ({className}) => {
  const navigate = useNavigate()
  const [data, setData] = useState<listRecentProject>(initialValues)
  const [projects, setProjects] = useState<any>([])
  const updateData = (fieldsToUpdate: Partial<listRecentProject>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }

  useEffect(() => {
    recentProject().then((res: any) => {
      setProjects(res.data.Data)
      console.log('test111', res.data.Data)
    })
  }, [])
  const viewClinicDetails = (Id: any) => {
    navigate('/claim-form/view/' + Id)
  }
  const {currentUser} = useAuth()
  const [claimaccess, setclaimcaccess] = useState<any>([])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Recent Projects</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            List of recently created projects
          </span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to view all data'
        >
          <Link
            to='/project/list'
            className='btn btn-sm btn-primary'
            // data-bs-toggle='modal'
            // data-bs-target='#kt_modal_invite_friends'
          >
            <KTSVG path='media/icons/duotune/files/fil001.svg' className='svg-icon-3' />
            View All
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-120px'> S.No</th>
                <th className='min-w-150px'>Date</th>
                <th className='min-w-140px'> Company Name</th>
                <th className='min-w-140px'> Project Name</th>
                <th className='min-w-120px'> Description</th>

                {claimaccess?.view === 1 ? <th className='min-w-100px text-end'>Actions</th> : ''}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {projects?.map((item: any, i: any) => {
                return (
                  <tr key={item.id}>
                    <td>{i + 1}</td>
                    <td>
                      {new Date(item.createdAt).toLocaleDateString('en-GB', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                      })}
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                            {item.users?.name}
                          </a>
                        </div>
                      </div>
                    </td>

                    <td>{item.name}</td>

                    <td>{item.description}</td>

                    {claimaccess?.view === 1 ? (
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <a
                            href='#'
                            onClick={() => viewClinicDetails(item.id)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/eye.svg'
                              className='svg-icon-3'
                            />
                          </a>
                        </div>
                      </td>
                    ) : (
                      ''
                    )}
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {ProjectTable}
